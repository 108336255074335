import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const GiantSwarmIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 354.3 354.3">
    <path
      fill="currentColor"
      d="M346.6,131.7L266.2,28.9c-3.8-4.7-10.8-5.4-15.5-1.6c-0.8,0.6-1.5,1.4-2.1,2.2l-34.1,48.7c-11.2-6.3-23.9-9.5-36.7-9.4
	c-13.6-0.1-27,3.3-39,9.7l-33-47.6c-3.5-5-10.3-6.2-15.3-2.7c-0.8,0.6-1.6,1.3-2.2,2.1L7.7,133.1c-3.3,4.1,2.2,9.5,6.2,6l69.3-61.7
	c2-1.8,5.2-1.6,7,0.4c0,0,0,0.1,0.1,0.1l8.6,26.9c-48.3,11.9-77.9,60.7-66,109c9.3,37.9,42,65.6,81,68.4
	c1.2,17.3,11.1,35.9,42.8,47.2c1.4,0.5,3-0.3,3.5-1.7c0.3-0.9,0.1-1.8-0.4-2.6c-3.1-4.2-6.4-9.9-7.5-16.2
	c14.7,5.3,30.7,5.5,45.5,0.7c-1.3,5.6-3.8,10.9-7.4,15.5c-0.9,1.2-0.7,2.9,0.5,3.8c0.7,0.6,1.7,0.7,2.5,0.4
	c31.9-11.3,42.7-30,44.8-47.4c49.8-1.4,88.9-42.9,87.5-92.7c-1.2-41.2-30.1-76.3-70.3-85.3l8.8-27.6c1.8-2.1,4.9-2.3,7-0.6
	c0,0,0.1,0,0.1,0.1l69.3,61.7C344.4,141.1,350,135.8,346.6,131.7z M137.1,257.6c-22.4,13.7-55.6,0.2-74.3-30.1
	c-18.7-30.4-15.6-66.1,6.7-79.8s55.6-0.2,74.3,30.1C162.5,208.2,159.4,243.9,137.1,257.6z M286.6,147.3c22.7,13.9,25.9,50,7.1,80.6
	S241.3,272,218.6,258c-22.7-13.9-25.9-50-7.1-80.6C230.2,146.8,263.9,133.3,286.6,147.3z"
    />
    <path
      fill="currentColor"
      d="M84.5,231.2c-0.7-2.7-0.9-5.4-0.9-8.1c-0.1-2.7,0.2-5.3,0.9-7.9l24.1-10.3l-24.1-10.4c-0.6-2.6-0.9-5.3-0.9-8
      c-0.1-2.7,0.2-5.4,0.9-8l41.8,17.9c0.6,2.7,0.9,5.5,0.9,8.3c0,2.9-0.3,5.8-0.9,8.6L84.5,231.2z"
    />
    <path
      fill="currentColor"
      d="M223.1,213c0,0-0.9-2-0.9-7.5c0-5.4,0.9-7.5,0.9-7.5h55.5c0,0,1,2,1,7.5c0,5.4-1,7.5-1,7.5H223.1z"
    />
  </SvgIcon>
);
