import * as authorizationv1 from '../../model/authorizationv1';

export const localSubjectAccessReviewGVK =
  authorizationv1.localSubjectAccessReviewGVK;

export const selfSubjectAccessReviewGVK =
  authorizationv1.selfSubjectAccessReviewGVK;

export const selfSubjectRulesReviewGVK =
  authorizationv1.selfSubjectRulesReviewGVK;
