export const localSubjectAccessReviewApiVersion = 'authorization.k8s.io/v1';
export const localSubjectAccessReviewGVK = {
  apiVersion: 'v1',
  group: 'authorization.k8s.io',
  plural: 'localsubjectaccessreviews',
};

export const selfSubjectAccessReviewApiVersion = 'authorization.k8s.io/v1';
export const selfSubjectAccessReviewGVK = {
  apiVersion: 'v1',
  group: 'authorization.k8s.io',
  plural: 'selfsubjectaccessreviews',
};

export const selfSubjectRulesReviewApiVersion = 'authorization.k8s.io/v1';
export const selfSubjectRulesReviewGVK = {
  apiVersion: 'v1',
  group: 'authorization.k8s.io',
  plural: 'selfsubjectrulesreviews',
};
