export const githubAppApiVersion = 'promise.platform.giantswarm.io/v1beta1';

export const githubAppGVK = {
  apiVersion: 'v1beta1',
  group: 'promise.platform.giantswarm.io',
  plural: 'githubapps',
};

export const githubRepoApiVersion = 'promise.platform.giantswarm.io/v1beta1';

export const githubRepoGVK = {
  apiVersion: 'v1beta1',
  group: 'promise.platform.giantswarm.io',
  plural: 'githubrepos',
};

export const appDeploymentApiVersion = 'promise.platform.giantswarm.io/v1beta1';

export const appDeploymentGVK = {
  apiVersion: 'v1beta1',
  group: 'promise.platform.giantswarm.io',
  plural: 'appdeployments',
};
